import Glide, { Controls, Breakpoints, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'
import 'fslightbox';

document.addEventListener('DOMContentLoaded', () => {
	const sliders = document.querySelectorAll('.glide')

	const sliderExtensions = {
		Controls,
		Breakpoints,
		Swipe,
		Autoplay
	}

	sliders.forEach((slider) => {
		const sliderOptions = {
			type: 'carousel',
			perTouch: slider.dataset.perView,
			perView: slider.dataset.perView,
			autoplay: Number(slider.dataset.autoplay) || 0,
			breakpoints: {
				768: {
					perTouch: 1,
					perView: 1
				}
			}
		}
		new Glide(slider, sliderOptions).mount(sliderExtensions)
	})
})

// menu-item--has-sub-items

var elements = document.getElementsByClassName("menu-item--has-sub-items");

var widthMobile = 768;
var currentWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;

if (currentWidth <= widthMobile) {
	var elements = document.getElementsByClassName("menu-item--has-sub-items menu-item--level-0");

	for (var i = 0; i < elements.length; i++) {

		elements[i].addEventListener('click', function (event) {
			if (this.__x.$data.open2 == true) {
				this.__x.$data.open2 = false;
			}
			
				
			if (this.__x.$data.open2 == true) {
				event.preventDefault();
			}
			else {
			}
			
			this.__x.$data.open2 = !this.__x.$data.open2;
		}, false);
	}
}
